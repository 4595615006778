import { permissoesEnum } from "@/types/enums";

export default defineNuxtRouteMiddleware((to) => {
  // TODO: remover navigate quando puder lançar a página de performance
  return navigateTo({
    name: "index",
  });

  const { $ability } = useNuxtApp();

  // página de performance
  if ($ability.cannot(permissoesEnum.VER_ROTA, "all")) {
    return navigateTo({
      name: "index",
    });
  }
});
